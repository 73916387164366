/** @jsx jsx */
import { jsx, Flex, Box, Styled, Button } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { Container } from "components/layout"

const Hero = ({ image, title }) => (
  <Box sx={{ position: "relative" }}>
    <Img fluid={image} alt="" />
    <Box
      sx={{
        bottom: [0, 3],
        position: ["relative", "absolute", "absolute"],
        width: "100%",
        mt: [1, 0, 0],
        mb: [1, 1, 1],
      }}
    >
      <Container>
        <Flex
          sx={{
            alignItems: "center",
            bg: "dark",
            borderRadius: "default",
            borderBottom: "1.125rem solid",
            borderBottomColor: "primary",
            px: 3,
            justifyContent: "space-between",
          }}
        >
          <Styled.h1 sx={{ color: "background" }}>{title}</Styled.h1>
          <Button as={Link} to="/" variant="return">
            Return Home
          </Button>
        </Flex>
      </Container>
    </Box>
  </Box>
)

export default Hero
