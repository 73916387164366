/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

const ButtonWrapper = ({ children }) => (
  <Flex
    sx={{
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      my: 3,
      mx: -2,
      "&>a": {
        m: 2,
      },
    }}
  >
    {children}
  </Flex>
)

export default ButtonWrapper
