/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Box } from "theme-ui"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Header from "./partials/header"
import Footer from "./partials/footer"
import Head from "./head"

import Hero from "components/hero"

import { Col, Container, Row } from "./index"
import { Small, Medium, Large, ExtraLarge, SubHead } from "components/type"
import ButtonWrapper from "components/button-wrapper"
import Placeholder from "components/placeholder"

const PageLayout = ({ children, pageContext, data }) => {
  const featuredImage = data.mdx.frontmatter.featuredImage.childImageSharp.fluid

  return (
    <Fragment>
      <Head
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.description}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          variant: "layout.root",
        }}
      >
        <Header />
        <Box
          id="main"
          as="main"
          sx={{
            width: "100%",
            flex: "1 1 auto",
            variant: "layout.main",
          }}
        >
          <Hero image={featuredImage} title={data.mdx.frontmatter.title} />
          <MDXProvider
            components={{
              Col,
              Container,
              Row,
              Placeholder,
              Small,
              Medium,
              Large,
              ExtraLarge,
              SubHead,
              ButtonWrapper,
            }}
          >
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </Box>
        <Footer />
      </Box>
    </Fragment>
  )
}

export const pageQuery = graphql`
  query PageQuery($fileAbsolutePath: String!) {
    mdx(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      body
      frontmatter {
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PageLayout
